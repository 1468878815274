/*
 * @Author:
 * @Description:
 * @Date: 2024-10-18 20:58:51
 * @LastEditors: Please set LastEditors
 * @FilePath: /rhcn-checkout-web/src/api/checkout.js
 */
import http from '@/utils/http';

export function getOrderInfo(data) {
  data.transactionWebsite = window.document.referrer;
  return http.post('/txn/checkoutDetail', data);
}

export function getCancelUrl(key) {
  return http.get(`/txn/cancel/${key}`);
}

export function payment(data) {
  return http.post('/txn/confirmPayment', data);
}

export function getStatus(key) {
  return http.get(`/txn/orderDetail/${key}`);
}

/**
 * 获取卡种，账单信息，分期信息
 * @param {*} data {country, cardType, cardNumber}
 * @returns
 */
export function getFieldConfig(data) {
  return http.post('/txn/queryFieldConfig', data);
}

/**
 * 获取分期信息
 * @param {*} data
 * @returns
 */
export function getInstallmentInfo(data) {
  return http.post('/txn/installment', data);
}

/**
 * 获取品牌信息
 * @param {*} key
 * @returns
 */
export function getBrandInfo(key) {
  return http.post(`/txn/brand/${key}`);
}

/**
 * 本地支付信息
 * @param {*} id
 * @returns
 */
export function getLocalPaymentInfo(id) {
  return http.get(`/txn/orderDetail/paymentInfo/${id}`);
}
